
import Vue from 'vue';
import { Document } from '@/types';

export default Vue.extend({
  name: 'Resources & Media',
  data() {
    return {
      isBusy: false,
      fields: [
        {
          key: 'name',
          label: 'Document Name',
          class: 'text-left',
        },
        {
          key: 'type',
          label: 'Type',
          class: 'text-left',
        },
        {
          key: 'download',
          label: 'Download',
        },
      ],
      documents: [
        {
          name: 'HG1R filling tutorial',
          type: 'Video',
          path: '/files/HydraGEN_Remote_Filling_Tutorial_1080.mp4',
        },
        {
          name: 'HG1R Installation Manual',
          type: 'PDF',
          path: '/files/dynaCERT_HydraGEN_HG1R_Installation_Manual_v_1_1_AUG_28_2024.pdf',
        },
        {
          name: 'HG1R Owners Manual',
          type: 'PDF',
          path: '/files/dynacert_HG1R_owner_manual_NOV_2023.pdf',
        },
        {
          name: 'Troubleshooting Flow Chart',
          type: 'PDF',
          path: '/files/Customer_Troubleshooting_FlowChart.pdf',
        },
        {
          name: 'dynaCERT company Introduction',
          type: 'Video',
          path: '/files/dynaCERT_company_Introduction.mp4',
        },
      ],
    };
  },
  methods: {
    downloadDocument(documentItem: Document): void {
      const link = document.createElement('a');
      link.href = documentItem.path;
      link.setAttribute('download', documentItem.name);
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
});
